import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import BannerImage from '../../components/Page/BannerImage'
import EventList from '../../components/Event/EventList'

const childrensFestivalEvents = ({data}) => {

	const events = data.eventPages.edges

	return (
        <Layout>
			<BannerImage image={data.bannerImage.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className={`p-4 md:p-12`}>
					<div className={`flex items-center justify-between mb-6`}>
						<h2 className={`text-3xl mb-6`}>2021 Boswell Book Festival Children's Festival Events</h2>
					</div>
					<EventList data={events} />
					<div className={`pt-4 md:pt-12`}>
						<Link className={`link text-2xl`} to={`/2021/events`}>2021 Festival Events</Link>
					</div>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  bannerImage: file(relativePath: {eq: "general/Bozzy-16-9-001.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
  eventPages: allMarkdownRemark(
    filter: {frontmatter: {category: {eq: "event"}, event_type: {eq: "childrens"}, event_visible: {eq: true}}}
    sort: {order: ASC, fields: [frontmatter___event_id]}
  ) {
    edges {
      node {
        frontmatter {
          author_1_image_credit
          book_1_shop_link
          book_1_title
          event_title
          event_id
          event_image {
            childImageSharp {
              gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
          }
          event_date
          event_end
          event_excerpt
          event_start
          event_type
          event_ft_id
        }
      }
    }
  }
}
`

export default childrensFestivalEvents
